import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation(); // This hook returns the location object that represents the current URL.

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the window.
  }, [location]); // React to changes in the location object.

  return null; // This component does not render anything.
}

export default ScrollToTop;
