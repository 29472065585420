import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Contact from './Contact';
import Home from './Home';
// import Pricing from './Pricing';
// import Template from './Template';
import ScrollToTop from './ScrollToTop';
import Whitepaper from './Whitepaper';
// import { Helmet } from "react-helmet";
// import Terms from './Terms';
// import Privacy from './Privacy';
// import About from './About';

function App() {
  return (
    <div>
      {/* <Helmet>
        <title>{'XIncentive'}</title>
        <link rel="icon" type="image/png" href={'/images/x6.png'} sizes="32x32" />
        <link rel="apple-touch-icon" sizes="57x57"  href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="114x114"  href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="72x72" href={'/images/x6.png'} />    
        <link rel="apple-touch-icon" sizes="144x144" href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="60x60"  href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="120x120" href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="76x76"  href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="152x152"  href={'/images/x6.png'} />
        <link rel="apple-touch-icon" sizes="180x180"  href={'/images/x6.png'} />
      </Helmet> */}
      <Router>
        <ScrollToTop />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/whitepaper" element={<Whitepaper />} />
            {/* <Route path="/contact" element={<Contact />} />
            <Route path="/template" element={<Template />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/about" element={<About />} /> */}
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
