import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Whitepaper() {
  return (
    <div>
        <Header />
        <div className='wp'>
            <div className='wp1'>White Paper for<br/>Crypto X Incentive Token (CIX)</div>
            <div className='wp2'>Abstract</div>
            <div className='wp3'>The  Crypto X Incentive Token (CIX) ntroduces an innovative blockchain-based loyalty program for businesses and consumers. Businesses allocate loyalty points to customers, which can be seamlessly converted into blockchain-based CIX tokens at the customer's discretion. These tokens reside in a shared wallet system, enabling scalability, security, and liquidity. The shared wallet operates similarly to a stock buyback program, ensuring sustained token availability and ecosystem growth. This white paper outlines the technical architecture, tokenomics, and operational framework for the CIX system.</div>
            <div className='wp4'>1.  Introduction</div>
            <div className='wp5'>Traditional loyalty programs suffer from inefficiencies, including fragmented point systems, lack of liquidity, and limited customer engagement. CIX leverages blockchain technology to address these challenges, offering a unified, tokenized solution that bridges traditional loyalty systems with decentralized financial ecosystems.</div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>For businesses</div>
                <div className='col-9 mr-0 wp5'>CIX provides a straightforward mechanism to issue loyalty points while ensuring cost control and scalability.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>For consumers</div>
                <div className='col-9 mr-0 wp5'>CIX allows loyalty points to gain value through tokenization, enabling conversion, trade, and integration into a decentralized financial (DeFi) ecosystem.</div>
            </div>
            <div className='wp4'>2.  System Overview</div>
            <div className='wp5'>The CIX ecosystem comprises the following core components:</div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Loyalty Point Allocation</div>
                <div className='col-9 mr-0 wp5'>Businesses are allocated a predefined number of loyalty points monthly, tracked in a centralized database. <br/><br/>Points are issued to customers based on business-specific rules (e.g., purchases, engagement</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Token Conversion</div>
                <div className='col-9 mr-0 wp5'>Customers can convert their loyalty points into CIX tokens through a user interface (app or web portal) <br/><br/>Conversion rates are predefined and dynamic, tied to the shared wallet liquidity and market conditions</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Shared Wallet System</div>
                <div className='col-9 mr-0 wp5'>A shared wallet  is established to hold the token reserves.<br/><br/>Tokens in the shared wallet are replenished through:<br/>Purchases from the open market (similar to a stock buyback program<br/>Proportional allocations from the CIX token pool.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Blockchain Integration</div>
                <div className='col-9 mr-0 wp5'>CIX tokens are issued on a blockchain network (e.g., Ethereum, Binance Smart Chain) to leverage security, scalability, and interoperability. <br/><br/>Smart contracts automate loyalty point conversion, token issuance, and wallet management.</div>
            </div>
            <div className='wp4'>3.  Technical Architecture</div>
            <div className='wp7'>3.1   System Workflow</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Point Issuance</div>
                <div className='col-9 mr-0 wp5'>Businesses access the  CIX Dashboard  to allocate points to customer accounts.<br/><br/>Points are stored in a centralized database, indexed by customer IDs.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Point Redemption</div>
                <div className='col-9 mr-0 wp5'>Customers request point conversion via the CIX App/Portal<br/><br/>The system validates point balances and initiates a blockchain transaction.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Token Allocation</div>
                <div className='col-9 mr-0 wp5'>Smart contracts mint CIX tokens from the shared wallet to the customer’s blockchain wallet.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Shared Wallet Management</div>
                <div className='col-9 mr-0 wp5'>An algorithm monitors wallet liquidity and initiates token buybacks when reserves fall below a threshold.</div>
            </div>
            <div className='wp7 mt-100'>3.2   Blockchain Smart Contract</div>
            <div className='wp5'>The CIX smart contract includes:</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Minting Logic</div>
                <div className='col-9 mr-0 wp5'>Tokens are minted only during point redemption, ensuring controlled supply.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Buyback Mechanism</div>
                <div className='col-9 mr-0 wp5'>A smart contract triggers token purchases from the market, using a predefined portion of revenues.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Conversion Rates</div>
                <div className='col-9 mr-0 wp5'>Dynamically calculated based on token supply, demand, and market price.</div>
            </div>
            <div className='wp7 mt-100'>3.3   Centralized Database</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Tracks</div>
                <div className='col-9 mr-0 wp5'>Loyalty point issuance.<br/>Redemption history.<br/>Business allocations and unused points.<br/>Acts as the primary data source for auditing and compliance.</div>
            </div>
            <div className='wp7 mt-100'>3.4    Interoperability Layer</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Integrates with</div>
                <div className='col-9 mr-0 wp5'>Business POS systems for point allocation.<br/>DeFi wallets for token storage and usage</div>
            </div>
            <div className='wp4'>4.  Tokenomics</div>
            <div className='wp7'>4.1. Token Supply</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Initial Supply</div>
                <div className='col-9 mr-0 wp5'>1 billion CIX tokens.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px'}}>
                <div className='col-3 wp6'>Circulating Supply</div>
                <div className='col-9 mr-0 wp5'> Adjusted via buybacks and user redemptions.</div>
            </div>
            <div className='wp5 mt-100'>4.2.  Revenue Streams</div>
            <div className='bar mt-30 mb-30'></div>
            <div className='wp5'>Businesses pay a monthly fee for loyalty point allocations.<br/><br/>Transaction fees for point-to-token conversions.</div>
            <div className='wp5 mt-100'>4.3.  Buyback Mechanism</div>
            <div className='bar mt-30 mb-30'></div>
            <div className='wp5'>A portion of revenue is used to buy tokens from the open market, replenishing the shared wallet.</div>
            <div className='wp5 mt-100'>4.4.   Incentive Program</div>
            <div className='bar mt-30 mb-30'></div>
            <div className='wp5'>Early adopters receive discounted point-to-token conversion rates.<br/>Businesses can earn bonus tokens for customer engagement milestones.</div>
            <div className='wp4'>5.  Security and Compliance</div>
            <div className='wp7'>5.1. Blockchain Security</div>
            <div className='row' style={{borderTop: '1px solid #D1D9E0', borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Consensus Mechanism</div>
                <div className='col-9 mr-0 wp5'> Uses Proof of Stake (PoS) for scalability and low energy consumption.</div>
            </div>
            <div className='row' style={{borderBottom: '1px solid #D1D9E0', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                <div className='col-3 wp6'>Audited Smart Contracts</div>
                <div className='col-9 mr-0 wp5'>Third-party audits ensure robustness.</div>
            </div>
            <div className='wp5 mt-100'>5.2.    Data Privacy</div>
            <div className='bar mt-30 mb-30'></div>
            <div className='wp5'>Customer data stored in the centralized database complies with GDPR and other privacy regulations.<br/>Only anonymized identifiers are linked to blockchain transactions.</div>
            <div className='wp5 mt-100'>5.3.    Fraud Prevention</div>
            <div className='bar mt-30 mb-30'></div>
            <div className='wp5 mb-60'>Points cannot be redeemed without validation against business records.<br/>Multi-signature wallets safeguard shared wallet transactions.</div>
        </div>
            <div className='wwp'>
                <div className='wp8'>6.  Roadmap</div>
                <div className='row' style={{borderBottom: '1px solid #676767', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                    <div className='col-3 wp9'>Phase 1</div>
                    <div className='col-9 mr-0 wp9'>Development (Q1 - Q2 2025)</div>
                </div>
                <div className='wp10 mt-30'>Smart contract design and blockchain deployment.<br/><br/>Development of the CIX dashboard and customer portal.</div>
                <div className='row' style={{borderBottom: '1px solid #676767', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                    <div className='col-3 wp9'>Phase 2</div>
                    <div className='col-9 mr-0 wp9'>Pilot Testing (Q3 2025)</div>
                </div>
                <div className='wp10 mt-30'>Partner with 50+ businesses for initial rollout.<br/><br/>Test shared wallet buyback mechanism.</div>
                <div className='row' style={{borderBottom: '1px solid #676767', paddingTop: '20px', paddingBottom: '20px', marginTop: '30px'}}>
                    <div className='col-3 wp9'>Phase 3</div>
                    <div className='col-9 mr-0 wp9'>Full Launch (Q4 2025)</div>
                </div>
                <div className='wp10 mt-30'>Public launch of the CIX token and loyalty platform.<br/><br/>Integration with major POS systems.</div>
            </div>
        <div className='wp'>
            <div className='wp2'>Conclusion</div>
            <div className='wp3'>The Crypto X Incentive Token (CIX) redefines loyalty programs by merging traditional point systems with blockchain technology. Its shared wallet system, dynamic tokenomics, and seamless integration with business ecosystems make it a game-changer for customer engagement and business growth. By leveraging buyback mechanisms and secure smart contracts, CIX ensures sustained liquidity, scalability, and customer value.</div>
            <div className='wp11 mt-84 mb-45'>Appendix</div>
            <div className='d-flex mobOne' style={{gap: '27px'}}>
                <div className='wp12 w-100'>
                    <div className='wp13'>A. Technical Specifications</div>
                    <div className='mt-18 wp14'>Blockchain Platform:   Ethereum/Binance Smart Chain.<br/>Token Standard:   ERC-20/BEP-20.<br/>Smart Contract Language:   Solidity.<br/>Database:   PostgreSQL for centralized storage.</div>
                </div>
                <div className='wp12 w-100'>
                    <div className='wp13'>B. Key Metrics</div>
                    <div className='mt-18 wp14'>Initial Token Price:  $0.001.<br/>Monthly Point Allocation per Business:   Variable based on subscription tier.<br/>Conversion Fee:  0.5% of transaction value.</div>
                </div>
            </div>
            <div className='wp12 w-50 mt-24'>
                <div className='wp13'>C. References</div>
                <div className='mt-18 wp14'>"Blockchain for Loyalty Programs," Journal of Financial Innovation, 2023.<br/>Ethereum White Paper, 2021.</div>
            </div>
        </div>
        <div className='wp15 mt-120'><div className='wpp'>This white paper serves as the foundation for the launch of CIX, empowering businesses and customers<br/> with a future-ready loyalty ecosystem.</div></div>
        <Footer />
    </div>
  )
}

export default Whitepaper