import React, { useState } from 'react';
import './Header.scss';
import { useNavigate } from 'react-router-dom';


function Header() {
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };
    let navigate = useNavigate();

    const goToHome = () => {navigate('/');};
    const goToContact = () => {navigate('/contact');};
    const goToTemplate = () => {navigate('/template');};
    const goToPricing = () => {navigate('/pricing');};
    const goToAbout = () => {navigate('/whitepaper');};
    const navigateToExternalUrl = () => {window.location.href = "https://api.walletly.ai/signup";};
    const navigateToExternalUrl1 = () => {window.location.href = "https://api.walletly.ai";};
    const navigateToExternalUrl12 = () => {window.location.href = "https://partner.xincentive.com";};

    return (
        <div className='d-flex justify-content-center'>
            <div className='header headernew d-flex align-items-center justify-content-between padding'>
                <div className='d-flex gap-30' style={{flexDirection: 'column'}}>
                    <div className='cp' onClick={goToHome}><img loading="lazy" width={150} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/c8aa486a-56e3-4532-b785-4ed3d0eec200/xincentive' alt="Logo"/></div>
                    {isMenuOpen&&<div className='show'>
                    <div className={`menu-items show align-items-center d-flex gap-4`} >
                        <div className='newtext text1 cp' style={{borderBottom: 'none', marginBottom: '12px'}} >Whitepaper</div>
                        <div className='button1p' onClick={navigateToExternalUrl12}><div className='newbtn cp' >Business</div></div>
                        </div>
                    </div>}
                </div>
                <button className="menu-toggle" onClick={toggleMenu}style={{marginBottom: 'auto'}}>{isMenuOpen ? '✖' : '☰'}</button>
                <div className={`menu-items align-items-center d-flex hide gap-4`}>
                    <div className='newtext text1 cp ' >Whitepaper</div>
                    {/* onClick={goToAbout} */}
                    <div className='button1p hide' onClick={navigateToExternalUrl12}><div className='newbtn cp' >Business</div></div>
                </div>
            </div>
        </div>
    );
}

export default Header;
