import React from 'react'
import { useNavigate } from 'react-router-dom';

function Footer() {
    let navigate = useNavigate();

  const goToContact = () => {navigate('/contact');};
  const goToPrivacy = () => {navigate('/privacy');};
  const goToTerms = () => {navigate('/terms');};
  const goToPricing = () => {navigate('/pricing');};
  const goToTemplate = () => {navigate('/template');};
  const goToHome = () => {navigate('/');};
  const goToWhitepaper = () => {navigate('/whitepaper');};

  return (
    <div className='newfooter'>

<div className='d-flex mobOne justify-content-space-between justify-self-center' style={{width: '60%', paddingTop: '100px'}}>
  <div className='mob-width'>
    <div>
      <img loading="lazy" width={139} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/6887c8a4-76df-412c-8047-ff753491a300/xincentive' />
    </div>
    <div className='footerlogotext mt-14'>Follow our journey<br/>
    and product updates</div>
  </div>
  <div className='gap-18 column'>
    <div className='footerdesc  cp' onClick={goToHome}>Home</div>
    <div className='footerdesc  cp' >Whitepaper</div>
    {/* onClick={goToWhitepaper} */}
  </div>
  <div className='gap-18 column'>
    <div className='footerdesc cp' >X</div>
    <div className='footerdesc cp' >Instagram</div>
  </div>


</div>
<div className='text-align-center mt-80'><img  style={{width: '60%'}} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/eb0ed834-94ea-4758-6dd9-507d13bf9e00/xincentive'/></div>

{/* <div className='footertext mt-18 mb-80'>{''}</div> */}


{/* <div className='show'>
  <div className='d-flex justify-content-space-between'>
    <div className='gap-18 column'>
      <div className='footerdesc cp' onClick={goToAbout}>Home</div>
      <div className='footerdesc cp' onClick={goToAbout}>Whitepaper</div>
    </div>
    <div className='gap-18 column mr-100 mb-80'>
      <div className='footerdesc cp' onClick={goToPricing}>X</div>
      <div className='footerdesc cp' onClick={goToTemplate}>Instagram</div>
    </div>
  </div>
</div> */}


    </div>
  )
}

export default Footer